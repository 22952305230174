
import { dispatchGetProductsWishlist } from '@/store/products/actions';
import { Component, Vue } from 'vue-property-decorator';
import ProductGridCard from '@/components/Products/ProductGridCard.vue';
import { readProductsList } from '@/store/products/getters';
import ProductsPagination from '@/components/Products/ProductsPagination.vue';
import { getLocalSessionId } from '@/utils';

@Component({
  components: {
    ProductGridCard,
    ProductsPagination,
  },
})

export default class Wishlist extends Vue {
  public perPage = 10;
  public page = 1;
  public loadingState: any = {
    list: false,
    page: true,
  };

  public async mounted() {
    this.handleChangePage(1, true);
    this.loadingState.page = false;
  }

  public async handleChangePage(page, force = false) {
    if (this.page != page || force) {
      this.loadingState.list = true;
      this.page = page;
      const rowQuery = `?page=${this.page}&per_page=${this.perPage}`;
      await dispatchGetProductsWishlist(
        this.$store, {data: {anon_session_id: getLocalSessionId()}, rowQuery},
      );
      this.loadingState.list = false;
    }
  }
  
  get productsResponse() {
    return readProductsList(this.$store);
  }
}
